import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IconButton from '@material-ui/core/IconButton'
import TeammateModal from 'components/home/TeammateModal'
import Button from 'components/common/Button'

import { TeamMemberItem } from 'types/component/homePage'
import { colors } from 'styles/colors'

import TEXT from 'locales/home.json'
interface TeammateCardProps {
  member: TeamMemberItem
}

const TeammateCard = ({
  member: { name, description, image, linkedInUrl, summary },
}: TeammateCardProps) => {
  const [isModalVisible, setModalVisibility] = useState(false)

  return (
    <div className="flex flex-col justify-between items-center w-full md:w-96">
      <div className="flex flex-col items-center text-center">
        <GatsbyImage
          alt={`The face of ${name}`}
          className="w-full object-contain border-red border-b-4"
          image={image}
        />
        <div className="flex items-center py-4 text-white">
          <h3 className="mr-1">{name}</h3>
          <IconButton
            aria-label="LinkedIn"
            href={linkedInUrl}
            rel="noreferrer"
            target="_blank"
          >
            <LinkedInIcon htmlColor={colors.primary.DEFAULT} />
          </IconButton>
        </div>
        <p className="text-body-1 pb-6">{summary}</p>
      </div>
      <Button onClick={() => setModalVisibility(true)}>
        {TEXT.section.team.button}
      </Button>
      <TeammateModal
        description={description}
        linkedInUrl={linkedInUrl}
        isVisible={isModalVisible}
        name={name}
        onClose={() => setModalVisibility(false)}
      />
    </div>
  )
}

export default TeammateCard
