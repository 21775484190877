import React from 'react'

interface LinkExternalProps {
  href: string
  className?: string
  children: React.ReactNode
}

const LinkExternal = ({ href, className, children }: LinkExternalProps) => {
  return (
    <a
      className={['text-primary no-underline', className].join(' ')}
      color="primary"
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      {children}
    </a>
  )
}

export default LinkExternal
