import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type ClientLogoProps = {
  image: IGatsbyImageData
  alt: string
}

const ClientLogo = ({ image, alt }: ClientLogoProps) => (
  <div className="flex justify-center w-1/2 md:w-1/4 p-4 py-6 md:px-10 md:py-4 lg:px-12">
    <GatsbyImage
      image={image}
      alt={alt}
      className="max-w-full max-h-20 object-scale-down"
      objectFit="scale-down"
    />
  </div>
)

export default ClientLogo
