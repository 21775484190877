import React from 'react'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'

import Button from 'components/common/Button'
import CompanyLogo from 'components/common/CompanyLogo'
import LinkExternal from 'components/common/LinkExternal'

import useBreakpoints from 'hooks/useBreakpoints'
import { calendlyLink, contactEmail, googleMapsLink } from 'constants/contact'

import TEXT from 'locales/home.json'

const SectionContact = () => {
  const { isMobileScreen } = useBreakpoints()

  const mapContainerStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '37rem',
    height: isMobileScreen ? '20rem' : '16rem',
    objectFit: isMobileScreen ? 'cover' : 'contain',
    objectPosition: isMobileScreen ? 'left' : 'center',
  }

  return (
    <section id="section-contact">
      <div className="container mx-auto flex flex-col justify-between gap-8 md:flex-row md:items-start pt-24 px-4 pb-11 md:pt-20 md:px-16 md:pb-16">
        <CompanyLogo className="mb-4 md:mb-0" />
        <LinkExternal href={googleMapsLink}>
          <img
            alt={'The location of Phase 2 office'}
            src={'map-phase2-desktop.png'}
            style={mapContainerStyle}
          />
        </LinkExternal>
        <div className="contact-meet flex flex-col justify-between w-48 gap-6 md:gap-8 mb-8 md:mb-0">
          <span className="whitespace-pre-wrap">{TEXT.company.address}</span>
          <Button secondary className="w-56">
            <LinkExternal href={calendlyLink}>
              {TEXT.section.contact.button}
            </LinkExternal>
          </Button>
          <LinkExternal href={`mailto: ${contactEmail}`} className="text-link">
            {TEXT.section.contact.contactUs}
          </LinkExternal>
        </div>
        <div className="flex flex-col justify-center items-center w-32 gap-5">
          <h3 className="self-start">{TEXT.section.contact.followUs}</h3>
          <div className="flex w-full justify-start gap-4">
            <LinkExternal href="https://www.linkedin.com/company/phase2-gmbh">
              <LinkedInIcon />
            </LinkExternal>
            <LinkExternal href="https://twitter.com/phase2gmbh">
              <TwitterIcon />
            </LinkExternal>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionContact
