import React from 'react'

import Button from 'components/common/Button'
import SectionTitle from 'components/common/SectionTitle'
import LinkExternal from 'components/common/LinkExternal'

import { calendlyLink } from 'constants/contact'

import TEXT from 'locales/home.json'

const SectionHero = () => {
  return (
    <section id="section-hero">
      <div className="container mx-auto flex flex-col-reverse justify-between mb-24 px-4 md:flex-row md:mb-40 md:mt-8 md:px-16">
        <div className="flex flex-col pt-20 md:pr-0">
          <SectionTitle position="left" isMainTitle>
            {TEXT.section.hero.title}
          </SectionTitle>
          <p className="max-w-md text-subtitle pb-10 md:pb-6">
            {TEXT.section.hero.description}
          </p>
          <div className="flex w-full justify-start self-center">
            <Button primary size="large" className="w-56">
              <LinkExternal
                href={calendlyLink}
                className="text-primary-darkest"
              >
                {TEXT.section.hero.button}
              </LinkExternal>
            </Button>
          </div>
        </div>
        <div className="max-w-lg flex justify-center p-8 md:p-0">
          <img src="/222.svg" alt="" width="100%" height="100%" />
        </div>
      </div>
    </section>
  )
}

export default SectionHero
