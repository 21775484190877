import React from 'react'
import ClientLogo from 'components/home/ClientLogo'
import SectionTitle from 'components/common/SectionTitle'

import { HomePageSectionProps } from 'types/component/homePage'
import { clientLogos } from 'constants/homePage'
import { getImageFromPath } from 'utils/image'

import TEXT from 'locales/home.json'

const SectionClients = ({ data }: HomePageSectionProps) => {
  return (
    <section id="section-clients" className="flex flex-col">
      <div className="container mx-auto px-4 pt-14 mb-24 md:mb-40 ">
        <div className="text-center" id="clients">
          <SectionTitle>{TEXT.section.clients.title}</SectionTitle>
          <p className="text-subtitle">{TEXT.section.clients.subtitle}</p>
        </div>
        <div className="w-full flex flex-wrap items-evenly justify-center">
          {clientLogos.map(client => (
            <ClientLogo
              key={client.name}
              image={getImageFromPath(client.imagePath, data)}
              alt={client.altText}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionClients
