import React from 'react'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IconButton from '@material-ui/core/IconButton'
import Modal from 'components/common/Modal'

import { colors } from 'styles/colors'

interface TeammateModalProps {
  description: string
  linkedInUrl: string
  isVisible: boolean
  name: string
  onClose: () => void
}

const TeammateModal = ({
  description,
  linkedInUrl,
  isVisible,
  name,
  onClose,
}: TeammateModalProps) => (
  <Modal isCloseButtonVisible isVisible={isVisible} onClose={onClose}>
    <div className="overflow-y-scroll md:overflow-y-hidden px-4 py-8 md:px-6 md:py-12 h-screen md:h-full">
      <div className="flex items-center mb-4 md:mb-6">
        <h3 className="mr-2">{name}</h3>
        <IconButton
          aria-label="LinkedIn"
          href={linkedInUrl}
          rel="noreferrer"
          target="_blank"
        >
          <LinkedInIcon htmlColor={colors.primary.DEFAULT} />
        </IconButton>
      </div>
      <div
        className="text-body-1 md:text-subtitle"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </Modal>
)

export default TeammateModal
