import { ClientLogoItem } from 'types/component/homePage'

export const clientLogos: ClientLogoItem[] = [
  {
    name: 'Centrifuge',
    imagePath: 'logo-centrifuge.png',
    altText: 'centrifuge logo',
  },
  {
    name: 'Crowdhouse',
    imagePath: 'logo-crowdhouse.png',
    altText: 'crowdhouse logo',
  },
  {
    name: 'Decentriq',
    imagePath: 'logo-decentriq.png',
    altText: 'decentriq logo',
  },
  {
    name: 'Frontify',
    imagePath: 'logo-frontify.png',
    altText: 'frontify logo',
  },
  {
    name: 'OrderFox',
    imagePath: 'logo-orderfox.png',
    altText: 'orderfox logo',
  },
  {
    name: 'Modulos',
    imagePath: 'logo-modulos.png',
    altText: 'modulos logo',
  },
  {
    name: 'Deepcode',
    imagePath: 'logo-deepcode.png',
    altText: 'deepcode logo',
  },
  {
    name: 'web3 foundation',
    imagePath: 'logo-web3.png',
    altText: 'web3 foundation logo',
  },
]
