import React from 'react'

import SectionTitle from 'components/common/SectionTitle'

import TEXT from 'locales/home.json'

const SectionAbout = () => {
  return (
    <section id="section-about" className="flex flex-col justify-center">
      <div className="container flex flex-col justify-between items-center px-4 mx-auto mb-28 md:mb-44">
        <div className="text-body-1 text-left">
          <SectionTitle>{TEXT.section.about.title}</SectionTitle>
          <div className="grid grid-col-1 md:grid-cols-2 gap-10 lg:gap-20 max-w-5xl mt-6">
            {TEXT.section.about.description.map((paragraph, index) => (
              <p key={'about-description-' + (index + 1)}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAbout
