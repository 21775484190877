import React from 'react'

import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SectionAbout from 'components/home/SectionAbout'
import SectionClients from 'components/home/SectionClients'
import SectionContact from 'components/home/SectionContact'
import SectionExpandTeam from 'components/home/SectionExpandTeam'
import SectionHero from 'components/home/SectionHero'
import SectionTeam from 'components/home/SectionTeam'
import SectionTestimonials from 'components/home/SectionTestimonials'

import { HomePageData } from 'types/component/homePage'

export const data = graphql`
  {
    allFile {
      nodes {
        path: relativePath
        childImageSharp {
          gatsbyImageData(
            width: 400
            placeholder: TRACED_SVG
            backgroundColor: "#051D30"
            tracedSVGOptions: { background: "#051D30", color: "#F60000" }
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`

const IndexPage: React.FC<{ data: HomePageData }> = ({ data }) => {
  return (
    <Layout>
      <main>
        <SectionHero />
        <SectionClients data={data} />
        <SectionTestimonials />
        <SectionTeam data={data} />
        <SectionAbout />
        <SectionExpandTeam />
        <SectionContact />
      </main>
    </Layout>
  )
}

export default IndexPage
