import React from 'react'
import { Link } from 'gatsby'
import { useHookstate } from '@hookstate/core'

import Button from 'components/common/Button'
import SectionTitle from 'components/common/SectionTitle'
import SingleSelect from 'components/common/SingleSelect'

import { roles } from 'types/team'

import useBreakpoints from 'hooks/useBreakpoints'
import configuratorStore from 'store/configurator'
import { setValue } from 'utils/setValue'

import TEXT from 'locales/home.json'

const SectionExpandTeam = () => {
  const { isMobileScreen } = useBreakpoints()
  const { team } = useHookstate(configuratorStore)

  return (
    <section
      id="section-expand-your-team"
      className="flex flex-col justify-center bg-primary-darkest"
    >
      <div className="container flex flex-col justify-between items-center my-20 px-4 md:mt-20 md:mb-24 mx-auto">
        <div className="max-w-2xl px-8">
          <SectionTitle>{TEXT.section.expandTeam.title}</SectionTitle>
          <p className="text-body-1 text-center">
            {TEXT.section.expandTeam.description}
          </p>

          <div className="flex flex-row justify-center gap-6 mt-10">
            {!isMobileScreen && (
              <SingleSelect
                className="w-80 hidden md:inline-block"
                items={roles}
                id="role"
                label={TEXT.section.expandTeam.selectPlaceholder}
                value={team[0].role.get()}
                onChange={setValue(team[0].role)}
                error={false}
              />
            )}
            <Button primary size="large" className="w-56">
              <Link to="/start">{TEXT.section.expandTeam.button}</Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionExpandTeam
