import { getImage, ImageDataLike, IGatsbyImageData } from 'gatsby-plugin-image'
import { HomePageData } from 'types/component/homePage'

export const getImageFromPath = (
  path: string,
  data: HomePageData
): IGatsbyImageData => {
  return getImage(
    data?.allFile.nodes.find(
      file => file.path === path
    ) as unknown as ImageDataLike
  ) as IGatsbyImageData
}
