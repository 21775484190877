import React, { useRef } from 'react'

import { IconButton } from '@material-ui/core'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'

import SectionTitle from 'components/common/SectionTitle'
import TestimonialCard from 'components/home/TestimonialCard'

import { TestimonialItem } from 'types/component/homePage'
import TEXT from 'locales/home.json'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper'

// eslint-disable-next-line import/no-unresolved
import 'swiper/css'

const SectionTestimonials = () => {
  const swiperRef = useRef<SwiperClass>()
  const testimonials: TestimonialItem[] =
    TEXT.section.testimonials.testimonialsList

  if (!testimonials.length) {
    return null
  }

  return (
    <section id="section-testimonials">
      <div className="container mx-auto mb-24 md:mb-40 ">
        <div className="text-center pb-12">
          <SectionTitle>{TEXT.section.testimonials.title}</SectionTitle>
        </div>
        <div className="relative w-full">
          <Swiper
            onBeforeInit={swiper => {
              swiperRef.current = swiper
            }}
            centeredSlides={testimonials.length === 1}
            centeredSlidesBounds={testimonials.length > 1}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="relative"
          >
            {testimonials.map((testimonial, index: number) => (
              <SwiperSlide key={index}>
                <TestimonialCard
                  text={testimonial.text}
                  author={testimonial.author}
                />
              </SwiperSlide>
            ))}
            <IconButton
              color="inherit"
              aria-label="previous"
              onClick={() => swiperRef.current?.slidePrev()}
              className="!absolute inset-y-1/3 z-50 h-[48px] left-0"
            >
              <ArrowBackOutlinedIcon />
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="next"
              onClick={() => swiperRef.current?.slideNext()}
              className="!absolute inset-y-1/3 z-50 h-[48px] right-0"
            >
              <ArrowForwardOutlinedIcon />
            </IconButton>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default SectionTestimonials
