import React from 'react'
import { TestimonialItem } from 'types/component/homePage'

type TestimonialProps = TestimonialItem

const Testimonial = ({ text, author }: TestimonialProps) => (
  <div className="flex flex-col justify-between items-center text-center w-full carousel-cell text-body-1">
    <span className="px-12 mb-4">“{text}“</span>
    <div className="flex flex-col items-center h-14 pt-2">
      <span>{author.name}</span>
      <span>{author.role}</span>
    </div>
  </div>
)

export default Testimonial
