import React from 'react'
import SectionTitle from 'components/common/SectionTitle'
import TeammateCard from 'components/home/TeammateCard'

import {
  HomePageData,
  HomePageSectionProps,
  TeamMemberItem,
} from 'types/component/homePage'
import { getImageFromPath } from 'utils/image'

import TEXT from 'locales/home.json'

const sanitizeTeamMemberList = (data: HomePageData): TeamMemberItem[] => {
  return TEXT.section.team.teamList.map(member => ({
    ...member,
    image: getImageFromPath(member.imagePath, data),
  }))
}

const SectionTeam = ({ data }: HomePageSectionProps) => {
  const teamMembers = sanitizeTeamMemberList(data)

  if (!teamMembers.length) {
    return null
  }

  return (
    <section id="section-team">
      <div className="container mx-auto px-4 md:px-16 mb-24 md:mb-40">
        <SectionTitle>{TEXT.section.team.title}</SectionTitle>
        <div className="flex flex-col md:flex-row items-center justify-center gap-14 pt-8 ">
          {teamMembers.map(member => (
            <TeammateCard key={member.name} member={member} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionTeam
